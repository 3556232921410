/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "fuelapi",
            "endpoint": "https://cl25zn5zm0.execute-api.us-east-2.amazonaws.com/stage",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://xkigukarcbbnzlaqx4owztaydm.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-heo2u6b5kvgcnnx7wteodkywti",
    "aws_cognito_identity_pool_id": "us-east-2:20543dab-e751-4c3d-abd6-8d3a49a9f0dc",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_dUNyuIcyI",
    "aws_user_pools_web_client_id": "4jsukoqc8b7lr4e4aojpeqn084",
    "oauth": {
        "domain": "go-sso-stage.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://fuel.york.ie/,https://stage.fuel.york.ie/",
        "redirectSignOut": "http://localhost:3000/,https://fuel.york.ie/,https://stage.fuel.york.ie/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "fuel-front-end-20191029134026-hostingbucket-stage",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d2bwzgoqnurw6v.cloudfront.net",
    "aws_dynamodb_all_tables_region": "us-east-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "admin-data-stage",
            "region": "us-east-2"
        },
        {
            "tableName": "email-settings-stage",
            "region": "us-east-2"
        },
        {
            "tableName": "slackauth-stage",
            "region": "us-east-2"
        },
        {
            "tableName": "slackdata-stage",
            "region": "us-east-2"
        },
        {
            "tableName": "slacktoken-stage",
            "region": "us-east-2"
        },
        {
            "tableName": "user-settings-stage",
            "region": "us-east-2"
        }
    ],
    "aws_user_files_s3_bucket": "articlesend-stage",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "aws_cognito_login_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
